export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GOOGLE_ID || "UA-25572633-1"

export const pageview = url => {
    window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
    })
}

export const event = ({ action, parameters = {} }) => {
    window.gtag("event", action, parameters)
}