'use client';

import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";
import CookieSettings from "./CookieSettings";

const CookieNotice = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [isViewMore, setViewMore] = useState(false);

    const router = useRouter();

    useEffect(() => {
        try {
            const cookiesAgreed = localStorage.getItem('previousVisits');
            if (!cookiesAgreed) {
                setIsVisible(true);
            }
        } catch (e) {
            console.error('Error checking cookies:', e);
        }
    }, []);

    const handleAcceptCookies = () => {
        localStorage.setItem('previousVisits', 'true');
        localStorage.setItem('saveLocations', 'true');
        localStorage.setItem('warningPopups', 'true');
        localStorage.setItem('understandingStats', 'true');
        localStorage.setItem('contentPerformance', 'true');
        localStorage.setItem('advertisingPerformance', 'true');
        localStorage.setItem('limitedDataAdvertising', 'true');
        localStorage.setItem('deviceCharacteristics', 'true');
        localStorage.setItem('preciseGeolocation', 'true');
        localStorage.setItem('dataTransmission', 'true');
        localStorage.setItem('limitedDataContent', 'true');
        setIsVisible(false);
    };


    return isVisible ? (
        <div>
            {!isViewMore ? (
                <>
                    <div className="fixed inset-0 backdrop-blur-none backdrop-brightness-50" style={{ zIndex: 9999 }} />
                    <div className="fixed bottom-0 md:bottom-[24px] left-1/2 transform -translate-x-1/2 bg-white p-4 md:p-8 rounded-md shadow-md w-full md:w-[90%]" style={{ zIndex: 10000 }}>
                        <div className="flex flex-col md:flex-row">
                            <div className="text-xs md:text-base md:w-[60%]">
                                <p className="pt-2 pb-4 text-[24px] md:text-[32px]">
                                    Dobrodošli!
                                </p>
                                <span className="py-2">
                                    Mi i naši partneri upotrebljavamo kolačiće, pristupamo općim i neosjetljivim podacima s vašeg uređaja te ih upotrebljavamo kako bismo prilagodili oglase i poboljšali vaš doživljaj stranice. <a className="text-[#0287C2] hover:text-[#02a7f1] transition duration-200 underline" href="/politika-privatnosti">Više o Politika privatnosti i kolačića</a>
                                </span>
                            </div>
                            <div className="flex justify-center items-center pt-4 ml-auto w-full md:w-[30%]">
                                <button
                                    className={`flex-1 rounded-lg border border-[#0287c2] mr-2 mb-2 bg-white text-[#0287c2]`}
                                    onClick={() => setViewMore(true)}
                                >
                                    <p className="py-3 px-3 font-semibold">Saznaj više</p>
                                </button>
                                <button
                                    className={`flex-1 rounded-lg border border-[#0287c2] mr-2 mb-2 bg-[#0287c2] text-white`}
                                    onClick={() => handleAcceptCookies()}
                                >
                                    <p className="py-3 px-3 font-semibold">Prihvati i zatvori</p>
                                </button>
                            </div>

                        </div>
                    </div>
                </>
            ) : (
                <CookieSettings setIsVisible={setIsVisible} />
            )}
        </div>
    ) : null;
}

export default CookieNotice;
