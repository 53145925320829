'use client';

import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

const AdModal = () => {
    const [isVisible, setIsVisible] = useState(true);

    const handleClose = () => {
        setIsVisible(false);
    };

    return isVisible ? (
        <div className="inline-block md:hidden">
            <div className="fixed inset-0 backdrop-blur" style={{ zIndex: 4001 }} />
            <div className="fixed top-0 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2 bg-white bg-opacity-90 p-4 md:p-8 rounded-md shadow-md w-full md:w-auto h-full flex flex-col justify-center" style={{ zIndex: 4002 }}>
                <div className="py-2 flex justify-center items-center">
                    <img src="bubble-tea.jpg" style={{height: "250px", width: "250px"}}/>
                </div>
                <div className="flex justify-center items-center pt-4">
                    <button
                        className={`flex-1 rounded-lg border border-[#0287c2] mr-2 mb-2 bg-[#0287c2] text-white`}
                        onClick={() => handleClose()}
                    >
                        <p className="py-3 px-3 font-semibold">Zatvori</p>
                    </button>
                </div>
            </div>
        </div>
    ) : null;
}

export default AdModal;
