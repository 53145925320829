'use client';

import { useRouter } from "next/navigation";
import { useEffect, useState } from "react";

const CookieSettings = ({ setIsVisible }) => {
    const [cookieSettings, setCookieSettings] = useState({
        saveLocations: false,
        previousVisits: false,
        warningPopups: false,
        understandingStats: false,
        contentPerformance: false,
        advertisingPerformance: false,
        limitedDataAdvertising: false,
        deviceCharacteristics: false,
        preciseGeolocation: false,
        dataTransmission: false,
        limitedDataContent: false
    });

    const router = useRouter();

    const checkCookies = () => {
        const settings = { ...cookieSettings };
        for (const cookieType in settings) {
            if (localStorage.getItem(cookieType)) {
                settings[cookieType] = true;
            } else {
                settings[cookieType] = false;
            }
        }
        setCookieSettings(settings);
    };

    useEffect(() => {
        checkCookies();

        if (window) {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
        }
    }, []);

    const handleAcceptCookies = (cookieType) => {
        localStorage.setItem(cookieType, 'true');
        setCookieSettings({ ...cookieSettings, [cookieType]: true });
    };

    const handleRejectCookies = (cookieType) => {
        localStorage.removeItem(cookieType);
        if (cookieType === 'saveLocations') {
            localStorage.removeItem('savedCities2');
        }
        if (cookieType === 'warningPopups') {
            localStorage.removeItem('lastWarningPopup');
        }
        setCookieSettings({ ...cookieSettings, [cookieType]: false });
    };

    const cookieOptions = [
        { type: 'previousVisits', label: 'Prethodni posjeti' },
        { type: 'saveLocations', label: 'Spremljene lokacije' },
        { type: 'warningPopups', label: 'Upozorenja' },
        { type: 'understandingStats', label: 'Razumijevanje publike kroz statistiku ili kombinacije podataka iz različitih izvora' },
        { type: 'contentPerformance', label: 'Mjerenje performansi sadržaja' },
        { type: 'advertisingPerformance', label: 'Mjerenje performansi oglašavanja' },
        { type: 'limitedDataAdvertising', label: 'Korištenje ograničenih podataka za odabir oglašavanja' },
        { type: 'deviceCharacteristics', label: 'Aktivno skeniranje karakteristika uređaja za identifikaciju' },
        { type: 'preciseGeolocation', label: 'Korištenje preciznih geolokacijskih podataka' },
        { type: 'dataTransmission', label: 'Prijenos podataka' },
        { type: 'limitedDataContent', label: 'Korištenje ograničenih podataka za odabir sadržaja' }
    ];

    return (
        <div>
            <div className="fixed inset-0 backdrop-blur" style={{ zIndex: 9999 }} />
            <div className="absolute xl:fixed top-0 md:top-1/2 left-1/2 transform -translate-x-1/2 md:-translate-y-1/2 bg-white bg-opacity-90 p-4 md:p-8 rounded-md shadow-md w-full lg:w-auto" style={{ zIndex: 10000 }}>
                <div className="flex justify-center items-center">
                    <img src="/LOGO.svg" alt="Weather Logo" />
                </div>
                <div className="text-xs lg:text-base">
                    <p className="py-2">
                        Dobro došli
                    </p>
                    <p className="py-2">
                        Mi i naši partneri stavljamo kolačiće, pristupamo općim i neosjetljivim podacima s vašeg uređaja te ih upotrebljavamo kako bismo poboljšali naše proizvode i prilagodili oglase i druge sadržaje na ovoj web stranici. Možete prihvatiti sve ili dio tih postupaka. Kako biste saznali više o kolačićima, partnerima i načinu na koji upotrebljavamo vaše podatke te pregledali svoje mogućnosti ili ove postupke za svakog partnera, posjetite našu stranicu: <span className="cursor-pointer text-blue-500 underline" onClick={() => router.push("/politika-privatnosti")}>Politika privatnosti i Politika kolačića</span>.
                    </p>
                </div>

                <div className="py-2 text-xs lg:text-base">
                    {cookieOptions.map(option => (
                        <div key={option.type} className="flex flex-col lg:flex-row lg:items-center">
                            <p>+ {option.label}</p>
                            <div className="flex flex-row lg:ml-auto">
                                <button
                                    className={`w-[90px] lg:w-[110px] rounded-lg border border-[#0287c2] mr-2 mb-2 ${cookieSettings[option.type] ? 'bg-[#0287c2] text-white' : 'bg-white text-[#0287c2]'}`}
                                    onClick={() => handleAcceptCookies(option.type)}
                                >
                                    <p className="px-3 font-semibold">Slažem se</p>
                                </button>
                                <button
                                    className={`w-[110px] lg:w-[130px] rounded-lg border border-[#0287c2] mr-2 mb-2 ${cookieSettings[option.type] ? 'bg-white text-[#0287c2]' : 'bg-[#0287c2] text-white'}`}
                                    onClick={() => handleRejectCookies(option.type)}
                                >
                                    <p className="px-3 font-semibold">Ne slažem se</p>
                                </button>
                            </div>
                        </div>
                    ))}
                    <div className="flex justify-center items-center pt-4">
                        <button
                            className={`flex-1 rounded-lg border border-[#0287c2] mr-2 mb-2 bg-[#0287c2] text-white`}
                            onClick={() => setIsVisible(false)}
                        >
                            <p className="py-3 px-3 font-semibold">Zatvori</p>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CookieSettings;
