'use client'

import { useRouter } from 'next/navigation';
import { useEffect, useRef } from 'react';
import IsLocalOrStaging from '../utils/IsLocalOrStaging';

const AdBanner = (props) => {
  useEffect(() => {
    try {
      if (!IsLocalOrStaging()){
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      }
    } catch (err) { }
  }, []);

  const clientId = process.env.NEXT_PUBLIC_GOOGLE_ADS_CLIENT_ID || 'ca-pub-0402558963479922';

  const adProps = {
    className: "adsbygoogle adbanner-customize",
    style: {
      display: 'block',
      overflow: 'hidden',
    },
    "data-ad-client": clientId,
    ...props,
  };

  return (
    <div key={props.customKey}>
      <ins {...adProps} />
    </div>
  );
};

export default AdBanner;
