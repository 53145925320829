"use client"
import { Icon } from '@iconify/react';
import React, { useState, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/navigation';

const UpozorenjaPopup = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [icons, setIcons] = useState([]);
    const [canBeVisible, setCanBeVisible] = useState(false);
    const router = useRouter();

    const handleSaznajVise = () => {
        setIsOpen(false);
        router.push('/upozorenja');
    }

    useEffect(() => {
        const fetchIcons = async () => {
            try {
                const response = await fetch('/api/bar-warnings');
                if (!response.ok) {
                    return;
                }
                const data = await response.json();
                setIcons(data.icons);

                const acceptedPopups = (localStorage.getItem('warningPopups'));

                if (acceptedPopups && data.icons.length > 0) {
                    const currentDayNumber = new Date().getDate();
                    if (!(acceptedPopups == currentDayNumber)) {
                        localStorage.setItem('warningPopups', currentDayNumber);
                        setCanBeVisible(true);
                    }
                }
            } catch (error) {
                console.error('Error fetching icons:', error);
            }
        }
        fetchIcons();

    }, []);

    useEffect(() => {
        if (canBeVisible) {
            setTimeout(() => {
                setIsOpen(true);
            }, 3000);
        }
    }, [canBeVisible]);

    const getIconDisplayName = (icon) => {
        switch (icon) {
            case 'ph:wind':
                return 'Vjetar';
            case 'carbon:rain-drop':
                return 'Kiša';
            case 'ph:snowflake':
                return 'Snijeg/Led';
            case 'mdi:thunder':
                return 'Grmljavina';
            case 'solar:fog-bold':
                return 'Magla';
            case 'mingcute:low-temperature-fill':
                return 'Niske temperature';
            case 'mingcute:high-temperature-fill':
                return 'Visoke temperature';
            default:
                return 'N/A';
        }
    }

    const getCroatiaDateToday = () => {
        //the output should be formatted like Petak, 24.11.2023
        const currentDatetime = new Date().toLocaleString("en-US", { timeZone: "Europe/Zagreb" });
    
        const dateInZagreb = new Date(currentDatetime);
    
        const day = dateInZagreb.toLocaleDateString('hr-HR', { weekday: 'long' });
    
        const dayNumber = dateInZagreb.getDate().toString().padStart(2, '0');
        const month = (dateInZagreb.getMonth() + 1).toString().padStart(2, '0');
        const year = dateInZagreb.getFullYear();
    
        return `${day.charAt(0).toUpperCase() + day.slice(1)}, ${dayNumber}.${month}.${year}`;
    }
    

    return (
        <div className={`${isOpen ? 'visible' : 'hidden'}`}>
            <div className="fadeInContainer fixed inset-0 backdrop-blur" style={{ zIndex: 9999 }} />
            <div className={`fadeInContainer fixed drop-shadow-md sm:max-w-[430px] top-0 right-0 sm:top-1/2 sm:left-1/2 transform sm:-translate-x-1/2 sm:-translate-y-1/2 w-screen h-screen sm:h-auto sm:w-auto z-[99999] ${isOpen ? '' : 'hidden'}`}>
                <div className="bg-blue-500 flex justify-between items-center bg-red-700 h-[61px]">
                    <div className="flex items-center mx-2.5 w-full text-white">
                        <Icon icon="mingcute:alert-fill" style={{ fontSize: '24px' }} />
                        <p className="font-semibold ml-2">Upozorenja</p>
                        <span className='ml-2 text-sm'>by <a href="https://meteo.hr/" className="underline">DHMZ</a></span>
                        <div className='ml-auto'>
                            <Icon onClick={() => setIsOpen(false)} icon="material-symbols:close" className="text-white text-2xl cursor-pointer" />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col bg-white p-2 h-[100%]">
                    <div className={`flex flex-col px-2 justify-center text-xs sm:text-base`}>
                        <p className='text-base font-semibold w-full text-center sm:text-lg py-2'>Danas - {getCroatiaDateToday()}</p>
                        <div className="flex flex-row justify-center items-center py-2 border-b">
                            {icons.map((icon, index) => (
                                <div className='flex flex-row items-center justify-center' key={"popup-icon-" + index}>
                                    <p className="font-semibold mr-1">{getIconDisplayName(icon)}</p>
                                    <Icon icon={icon} className="text-2xl mr-4" />
                                </div>
                            ))}
                        </div>
                        <div className="flex flex-row items-center py-2 pt-8 border-b">
                            <Icon icon="material-symbols:circle" className="mr-1" style={{ color: 'rgba(132, 206, 8, 1)' }} />
                            <p className="font-semibold mr-4">Zeleno</p>
                            <p className="ml-auto">Nema upozorenja</p>
                        </div>
                        <div className="flex flex-row items-center py-2 border-b">
                            <Icon icon="material-symbols:circle" className="mr-1" style={{ color: 'rgba(255, 230, 99, 1)' }} />
                            <p className="font-semibold mr-4">Žuto</p>
                            <p className="ml-auto">Vrijeme je potencijalno opasno</p>
                        </div>
                        <div className="flex flex-row items-center py-2 border-b">
                            <Icon icon="material-symbols:circle" className="mr-1" style={{ color: 'rgba(255, 180, 111, 1)' }} />
                            <p className="font-semibold mr-4">Narančasto</p>
                            <p className="ml-auto">Vrijeme je opasno</p>
                        </div>
                        <div className="flex flex-row items-center py-2 border-b">
                            <Icon icon="material-symbols:circle" className="mr-1" style={{ color: 'rgba(253, 82, 83, 1)' }} />
                            <p className="font-semibold mr-4">Crveno</p>
                            <p className="ml-auto">Vrijeme je izuzetno opasno</p>
                        </div>
                    </div>
                    <div className='flex justify-center items-center py-4'>
                        <p className="cursor-pointer text-[#0287C2] hover:text-[#02a7f1] transition duration-200 underline text-sm sm:text-base" onClick={() => handleSaznajVise()}>Saznaj više</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UpozorenjaPopup;
